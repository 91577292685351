
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
// -- Constants - -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
// -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// Identifiers to target based on the supported state
const IS_SUPPORTED_BROWSER_ID = "supported-browser";
const IS_NOT_SUPPORTED_BROWSER_ID = "not-supported-browser";
const SAFARI_BROWSER_OPTION_ID = "safari-browser-option";

// A regular expression matching the supported browsers from
// the shared .browserslistrc config. Populated by DEFINE_PLUGIN
declare const BROWSER_REGEX: string;

// Regest to detect Safari > 13+ to be used with IPadOS check
// Generated with https://github.com/browserslist/browserslist-useragent-regexp
declare const SAFARI_13_REGEX: string;

const SAFARI_COMMA_VERSION_REGEX = /(Version\/\d+\.\d+)\,(\d+ Safari\/)/;

const isSupportedBrowserFn = () => {
  let userAgent = navigator.userAgent;

  // Correct useragent for iOS 15.6+ that has version number with d.d,d instead of d.d.d
  // https://github.com/browserslist/browserslist-useragent-regexp/issues/1409
  if(SAFARI_COMMA_VERSION_REGEX.test(userAgent)) {
    userAgent = userAgent.replace(SAFARI_COMMA_VERSION_REGEX, "$1.$2");
  }

  return new RegExp(BROWSER_REGEX).test(userAgent)
    // iPad on iOS > 13 detection
    || (new RegExp(SAFARI_13_REGEX).test(userAgent) && userAgent.includes("Mac") && navigator.maxTouchPoints >= 1);
}

(() => {
  
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  // -- Browser Support -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  
  // Set display block for the provided element
  const showElement = (targetId: string) => {
    const targetElement: HTMLElement | undefined = document.getElementById(targetId);
    if (!targetElement) {
      return;
    }
  
    targetElement.style.display = "block";
  }
  
  // Extract browser info from navigator
  const isMac: boolean = /Mac/i.test(navigator.platform)
  const isSupportedBrowser: boolean = isSupportedBrowserFn();

  // Target the corresponding element and change its
  // display state
  const targetElementId: string = isSupportedBrowser ?
    IS_SUPPORTED_BROWSER_ID :
    IS_NOT_SUPPORTED_BROWSER_ID
  
  showElement(targetElementId);
  
  // We should not show Safari to non Mac users
  if (!isSupportedBrowser && isMac) {
    showElement(SAFARI_BROWSER_OPTION_ID);
  }
})();
